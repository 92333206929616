import React, { useEffect, useState } from 'react';
import useSWRHook from '../../hooks/useSWR';
import { Flex, Button, Text, HStack, useMediaQuery } from '@chakra-ui/react';
import Link from 'next/link';
import { LuCookie } from 'react-icons/lu';

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const [isLargerThan728] = useMediaQuery('(min-width: 728px)');

  const { data }: { data: any } = useSWRHook(`/api/cookies`);

  useEffect(() => {
    if (data?.success === 'false' || data?.success === null) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [data]);

  const handleAccept = async () => {
    let data = await fetch('/api/cookies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: 'vrt_cookies',
        value: true,
        duration: 3600 * 24 * 30 * 12,
      }),
    });

    if (data) {
      setShowBanner(false);
    }
  };

  const handleReject = async () => {
    await fetch('/api/cookies', {
      method: 'POST',
      body: JSON.stringify({ value: false }),
    });
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Flex
      position={'fixed'}
      justify={'space-between'}
      bottom={0}
      backgroundColor={'white'}
      w={'100%'}
      p={3}
      direction={isLargerThan728 ? 'row' : 'column'}
      px={isLargerThan1024 ? 20 : 3}
      borderTopWidth={1}
      borderTopColor={'alphaBlack.900'}
      zIndex={5}
    >
      <HStack spacing={7}>
        <LuCookie size={30} />
        <Text m={0} fontSize={14} maxW={'85%'}>
          We use cookies to ensure that we give you the best experience on our
          website. If you continue to use this site we will asume that you
          accept and understand our{' '}
          <Link href="/privacy-policy" style={{ textDecoration: 'underline' }}>
            Privacy Policy
          </Link>
        </Text>
      </HStack>
      <HStack
        mt={isLargerThan728 ? 0 : 5}
        justify={isLargerThan728 ? '' : 'center'}
      >
        <Button
          name="accept"
          onClick={() => handleAccept()}
          backgroundColor={'brand.900'}
          color={'white'}
          _hover={{
            opacity: 0.7,
          }}
        >
          Accept
        </Button>
        <Button
          name="reject"
          onClick={() => handleReject()}
          backgroundColor={'#0b0b0b'}
          color={'white'}
          _hover={{
            opacity: 0.7,
          }}
        >
          Reject
        </Button>
      </HStack>
    </Flex>
  );
};

export default CookieConsentBanner;
