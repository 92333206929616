import Script from 'next/script';

const Zoho = () => {
  return (
    <Script id="zsiqchat" type="text/javascript">
      {zohoSalesIQScript}
    </Script>
  );
};

export default Zoho;

const zohoSalesIQScript = `
var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq235b8c43d21b2f18313bc9bd8504c13d", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
`;
