import { CustomError } from '../components/Error'

export const fetcher = async <Data>(url: string): Promise<Data> => {
  const response = await fetch(url)
  if (!response.ok) {
    const errorData = await response.json()
    throw new CustomError(response.status, errorData.message)
  }
  const data = await response.json()
  return data as Data
}
