import { PublicConfiguration } from 'swr/_internal';
import { fetcher } from '../lib/fetcher';
import useSWR from 'swr';

const useSWRHook = <Data>(
  url: string,
  options: Partial<PublicConfiguration> = {}
) => {
  const { data, error, mutate } = useSWR(url, fetcher, options);

  let currentError = null;
  if (error) {
    if (error.statusCode === 401) {
      currentError = {
        title: 'Error',
        description: `${error.statusCode} Not authenticated`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      };
    } else if (error.statusCode === 403) {
      currentError = {
        title: 'Error',
        description: `${error.statusCode} Not authorized`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      };
    } else {
      currentError = {
        title: 'Error',
        description: `${error.statusCode} Try again`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      };
    }
  }

  if (error) {
    console.error(error);
    // setGlobalData({
    //   ...globalData,
    //   notification: {
    //     title: error.title,
    //     description: error.description,
    //     status: error.status,
    //     duration: error.duration,
    //     isClosable: error.isClosable,
    //   },
    // });
  }

  return {
    data: data as Data,
    error: currentError,
    mutate,
  };
};

export default useSWRHook;
